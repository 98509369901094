import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getVote } from '@/shared/api/vote/getVote';
import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { vote } from '@/shared/api/vote/vote';
import { globalErrorHandler } from '@/shared/api/queryClient';
import { useTranslations } from 'next-intl';
import { useLocalizedRouter } from '@/shared/i18n/routing';

type Params = Parameters<typeof getVote>[0];

export const useVote = (params: Params) => {
  const router = useLocalizedRouter();
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();

  const errorsTranslations = useTranslations('errors');

  const [isOver, setIsOver] = useState(false);

  const query = useQuery({
    queryKey: getUseVoteQueryKey(params),
    queryFn: () => getVote(params),
    select: (data) => (data ? [data?.[0]?.[0], data?.[1]?.[0]] : null),
    structuralSharing: false,
    retry: (failureCount, error) => {
      return isOverError(error) ? false : failureCount < 3;
    },
    enabled: !searchParams.get('code'),
    gcTime: 0,
    meta: {
      skipGlobalErrorHandler: true,
    },
  });

  useEffect(() => {
    if (query.isError) {
      console.log('[OverError]-3', { error: query.error });
      const isOver = isOverError(query.error);
      console.log('[OverError]-4', { isOver });
      setIsOver(isOver);

      if (!isOver) {
        globalErrorHandler(query.error, errorsTranslations);
        router.replace('/', { scroll: false });
      }
    } else {
      setIsOver(false);
    }
  }, [query.isError]);

  const mutation = useMutation({
    mutationFn: vote,
    onSuccess: () => {
      void queryClient.refetchQueries({
        queryKey: getUseVoteQueryKey(params),
      });
    },
  });

  return { query, mutation, isOver };
};

export const getUseVoteQueryKey = (params: Params) =>
  ['getVote', params].filter(Boolean);

const isOverError = (error: unknown) => {
  console.log('[OverError]', { error });
  return Boolean(
    error && isAxiosError(error) && error.response?.status === 400
  );
};
